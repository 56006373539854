
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url("../../../fonts/open-sans/fonts/Regular/OpenSans-Regular.eot?v=1.1.0");
  src: url("../../../fonts/open-sans/fonts/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../../../fonts/open-sans/fonts/Regular/OpenSans-Regular.woff2?v=1.1.0") format("woff2"), url("../../../fonts/open-sans/fonts/Regular/OpenSans-Regular.woff?v=1.1.0") format("woff"), url("../../../fonts/open-sans/fonts/Regular/OpenSans-Regular.ttf?v=1.1.0") format("truetype"), url("../../../fonts/open-sans/fonts/Regular/OpenSans-Regular.svg?v=1.1.0#Regular") format("svg");
  font-weight: normal;
  font-style: normal; }
/* END Regular */

/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url("../../../fonts/open-sans/fonts/Semibold/OpenSans-Semibold.eot?v=1.1.0");
  src: url("../../../fonts/open-sans/fonts/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../../../fonts/open-sans/fonts/Semibold/OpenSans-Semibold.woff2?v=1.1.0") format("woff2"), url("../../../fonts/open-sans/fonts/Semibold/OpenSans-Semibold.woff?v=1.1.0") format("woff"), url("../../../fonts/open-sans/fonts/Semibold/OpenSans-Semibold.ttf?v=1.1.0") format("truetype"), url("../../../fonts/open-sans/fonts/Semibold/OpenSans-Semibold.svg?v=1.1.0#Semibold") format("svg");
  font-weight: 600;
  font-style: normal; }
/* END Semibold */
