/* GENERAL
/*****************************************************************************/
body { color: #676a6c; font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 12px; }
@media print {
    #header-container { display: none }
    #sidebar { display: none }
    #footer { display: none }
    .btn { display: none }
    .do-not-print { display: none }
    .table td { padding: 2px }
    audio { display: none }
    table td { border: 1px solid black; }
    a { display: none }
    nav.navbar-static-side { display: none; }
    #page-wrapper { margin: 0; }
}
.dark-gray-bg { background-color: #e6e6e6; }
a:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus { outline: 0; }

a { color: #337ab7;text-decoration: none;}

/* dispositionBgColor
/*****************************************************************************/
.status-ready       { background-color: #cce29b; color: #333; }
.status-queue       { background-color: #FCFAE5; color: #333; }
.status-incall      { background-color: #9bcb3d; color: #333; }
.status-paused      { background-color: #e5e5e5; color: #333; }
.status-closer      { background-color: #FCFAE5; color: #333; }
.status-dispo       { background-color: #FFD280; color: #333; }
.status-dead        { background-color: #F59798; color: #333; }
.status-ringing     { background-color: #d9edf7; color: #333; }
.status-total       { background-color: #54c4c9; color: #333; }

/* LOGIN
/*****************************************************************************/
.loginscreen.middle-box h2 { margin: 5px 0; }
.login-form-wrapper { margin-top: 120px; margin-bottom: 20px; }
.login-form-content { max-width: 450px; padding: 25px 25px 7px 25px ; margin: 0 auto; border-bottom: 5px solid #c2436f; }
.login-form-footer-signature { font-size: 10px; }
.login-form-footer-signature a { margin-right: 10px; }
.login-form-footer-signature i.fa { margin-right: 5px; }
.login-options-buttons-group { margin: 0 0 20px 0;}
.login-options-buttons-group .btn {font-size: 13px;}

/* TABLES
/*****************************************************************************/
th.actions,
td.actions { width: 130px; text-align: right; }
.small-font-table * { font-size: 11px; }
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td { padding: 6px; }
.table-vertical-align-middle > thead > tr > th,
.table-vertical-align-middle > tbody > tr > th,
.table-vertical-align-middle > tfoot > tr > th,
.table-vertical-align-middle > thead > tr > td,
.table-vertical-align-middle > tbody > tr > td,
.table-vertical-align-middle > tfoot > tr > td { vertical-align: middle; }
.recordings-table audio { display: inline-block; vertical-align: top; }
.recordings-table th.actions,
.recordings-table td.actions { text-align: right; width: 210px; }

/* LAYOUT
/*****************************************************************************/
.nav-header { padding: 17px 11px; background: #00060d none repeat scroll 0 0; }
body.mini-navbar .nav-header { padding: 0; background: #00060d none repeat scroll 0 0; }
.logo-element { padding: 12px 0; }
.wrapper-content { padding: 0 10px 40px; }
.page-heading { margin: 0 -25px 10px -25px; border-top: 0 none; padding: 10px; }
.page-heading h2 { margin: 10px; }
.page-heading .breadcrumb { margin: 10px; }
.page-heading .title-with-action .breadcrumb { margin-left: 0; }
.page-heading .title-with-action h2 { padding-top: 0; }
.page-heading > div > h2 { padding-top: 10px; margin: 10px 0; }
.title-action { padding: 10px 0; text-align: right; }
.pagination { margin: 0; }
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover { color: #ccc; }
.alert { margin: 10px 0; }
#layout-flash { margin: 10px -15px; }
.nav.navbar-right > li > a.lougout-link { color: #ed5565; }
.navbar-top-links li:last-child { margin-right: 0; }
.navbar-header { float: left; margin-left: -30px; }
.mini-navbar .nav-second-level { width: 200px; }
#page-content { padding: 25px; }

/* NAVBAR
/*****************************************************************************/

/*.navbar-minimalize { margin-top: 10px; }*/
.nav-second-level li a { font-size: 11px; padding: 7px 10px 7px 46px; }
.navbar-form-custom .form-control { border-bottom: 1px solid #ddd; font-size: 12px; height: 34px; margin-top: 10px; }
.navbar-form-custom .form-control:focus { border-bottom: 1px solid #008cba; }
.navbar-top-links .open { background: #fff none repeat scroll 0 0; }
.navbar-static-side #side-menu i { color: #c2436f; }
.navbar-top-links .text-muted { color: #c2c2c2; }
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus { border-left: 2px solid #c2436f; color: #c2436f; background-color: #faebf4; }
.navbar-default .nav > li > a:focus { background-color: #50163a; }
.navbar-default .nav > li.active { background: #50163a; color: #efefef; }
.navbar-default #side-menu .nav-second-level > li > a:hover,
.navbar-default #side-menu .nav-second-level > li > a:focus,
.navbar-default #side-menu li.dropdown.active a:hover,
.navbar-default #side-menu li.dropdown.active a:focus { border-left: none; }
.navbar-default #side-menu .arrow { margin-top: 2px; }
body.mini-navbar .navbar-default .nav > li > .nav-second-level li a { border-radius: 0; }
.navbar-default .nav-group-title { border-bottom: 1px solid #303030; color: #bbbabe; font-size: 11px; font-weight: bold; margin: 30px 15px 0 15px; padding: 5px 0; text-transform: uppercase; }
.profileBox { color: white; }
.campaigns-sales-stats { color: #ebebeb; font-size: 10px; }
.campaigns-sales-stats .campaigns-sales-stats-line { margin: 10px -5px 10px 17px; }
.campaigns-sales-stats .label-campaigns-sales-stats { font-size: 10px; width: 28px; display: block; text-align: center; padding: 3px 8px; }

/* FORMS
/*****************************************************************************/
.form-horizontal .horizontal-form-submit { margin-left: -5px; }
/*.icheckbox_square-green,
.iradio_square-green { margin: 5px 0; }*/
.filter-form-container { margin-bottom: 5px; }
.filter-form-container .row { margin: 0; }
.filter-form-container .form-group { margin-bottom: 5px; }
.filter-form-container .form-group .control-label { padding: 7px 0 0 0; }
.filter-form-container #advancedFilter .form-group .control-label { padding: 8px 0 0 0; }
.filter-form-container .dropdown-menu { min-width: 240px; }
.filter-form-container .form-group .form-control { font-size: 12px; }
.filter-form-container .form-group label { font-size: 12px; }
.filter-form-container .multiselect.btn-default { font-size: 12px; }
.filter-form-container .ibox-content { padding: 20px; }
.filter-form-container .ibox-title { height: 38px; padding: 8px 14px 7px; }
.filter-form-container .date-range-caret { margin: 7px; position: absolute; right: 15px; }
.multiselect { margin-top: 4px; }
.filter-form-container .search-form-actions-row { margin-left: -5px; }
.multiselect-item .input-group-addon { font-size: 14px; }
.multiselect.btn-default { background-color: white; border-color: #bababa; color: black; }
.show-as-calendar-control { margin-left: 30px; }
input {height: 35px;padding: 0 5px;}
select{height: 30px; padding: 0px 5px;margin: 3px 0;}
/* MODALS
/*****************************************************************************/
.modal-body { background: #f8f8f8; }

/* DASHBOARD
/*****************************************************************************/
.productivityRating { position: relative; text-align: right; top: -40px; right: 5px; }
.productivityRating span { font-size: 14px; }
.dashboard .ibox-title { height: 38px; padding: 8px 14px 7px; }
.dashboard-indicators { margin-top: -20px; margin-bottom: 10px; }
.list-group-item .progress { margin-bottom: 0px; min-height: 10px; }
.left-bordered-dashboard-box { border-left: 1px solid #eeeeee; }

/* FIXED WIDTH LABELS
/*****************************************************************************/
.volumes-horaires-table .label { font-size: 10px; width: 60px; display: block; }
.contacts-par-campagne-table .label { font-size: 10px; width: 60px; display: block; }
.leads-count-label { font-size: 10px; width: 60px; display: block; }
.repertoire-contacts-table .label-qualification { font-size: 10px; width: 160px; display: block; }
.repertoire-contacts-table .label-agent { font-size: 10px; width: 120px; display: block; }
.tickets-table .label-severite { font-size: 10px; width: 100px; display: block; }
.label-call-direction { font-size: 10px; width: 80px; display: block; text-align: left; padding: 3px 10px; }
.label-call-direction i { margin-top: -3px; }

/* APPLICATION
/*****************************************************************************/
.lists-contacts-label { display: inline-block; width: 60px; }
.listes-table .icheckbox_square-green,
.listes-table .iradio_square-green { margin: 0; }
.listes-table .progress { margin-bottom: 0px; min-height: 10px; }
.tableList { margin: 0; padding: 0; }
.tableList li { background-position: 0 4px; background-repeat: no-repeat; list-style-type: none; }
.listes-appels * { font-size: x-small; }
.highcharts-button { color: red; }
.waiting { background-color: #FFF; opacity: 0.89; padding: 50px; position: absolute; bottom: 0px; top: 0px; left: 0px; right: 0px; text-align: center; }
#sidebar_publipostage { margin-left: 10px; width: 328px; }
#recycleTable .daterangepicker { z-index: 1000000; }
div.mce-fullscreen { z-index: 1050; }

/* TABS
/*****************************************************************************/
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus { -moz-border-bottom-colors: none; -moz-border-left-colors: none; -moz-border-right-colors: none; -moz-border-top-colors: none; background: none; border-color: #dddddd #dddddd rgba(0, 0, 0, 0); border-bottom: white; border-image: none; border-style: solid; border-width: 1px; color: black; cursor: default; }
.panel-body { -webkit-border-radius: 4px; -webkit-border-top-left-radius: 0; -webkit-border-top-right-radius: 0; -moz-border-radius: 4px; -moz-border-radius-topleft: 0; -moz-border-radius-toplright: 0; border-radius: 4px; border-top-left-radius: 0; border-top-right-radius: 0; border-color: white #dddddd #dddddd; border-style: solid; border-width: 1px; padding: 15px; }

/* SALES CALENDAR
/*****************************************************************************/
#sales-calendar .fc-event { border: none; border-left: 10px solid; }

/* INBOUND ICONS
/*****************************************************************************/
.fa-rotate-45 { -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); }
.fa-rotate-225 { -webkit-transform: rotate(225deg); -moz-transform: rotate(225deg); -ms-transform: rotate(225deg); -o-transform: rotate(225deg); transform: rotate(225deg); }


div.dataTables_paginate {
    margin-top: 30px;
    text-align: center;
}
div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
}
.pagination {
    border-radius: 4px;
    display: inline-block;
    margin: 0;
    padding-left: 0;
}
.pagination > li {
    display: inline;
}
.pagination > li > a, .pagination > li > span {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
}
.pagination > li:first-child > a, .pagination > li:first-child > span {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    margin-left: 0;
}
.pagination > li:last-child > a, .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
    background-color: #fff;
    border-color: #ddd;
    color: #777;
    cursor: not-allowed;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #f4f4f4;
    border-color: #dddddd;
    color: inherit;
    cursor: default;
    z-index: 2;
}
.help-block{
    color: #FF0000 !important;
}
.field-bloc {
    font-weight: bold;
}
.field-value-bloc {
    border-bottom: 1px dashed #eee;
    padding: 10px 0;
}
.dynamic-infos-col {
    margin-top: 5px;
    padding: 0 20px;
}
.checks{
    height: 10px;
}
.input-group input[type="checkbox"]{
    height: 25px;
}
@media only screen and (max-width:767px) {
    .m-npl300{
        padding-left: 0 !important;
    }
    .filter-form-container .search-form-actions-row{
        margin-left: 0 !important;
    }
    .m-pl15{
        padding-left: 15px !important;
    }
    .m-tl{
        text-align: left !important;
    }
    .m-dnn{
        display: none !important;
    }
}

/*

Toggle Msg Block

*/
#b-down{
    display: none;
}

.actionToAdd,
.actionToDelete{
    position: absolute;
    right: 16px;
    top: 12px;
}

.slideDownBlock{
    color: #ed5565;
    position: absolute;
    right: 0;
    top: 0;
}
.slideDownBlock:hover{
    color: #ed5565;
}
.actionToAdd{
    color: rgba(51, 122, 183, 0.6);
}
.actionToDelete{
    color: rgba(194, 67, 111, 0.6);
}

.block-route{
    position: relative;
    padding: 10px 0 20px;
}

.block-route-add,
.block-route-delete{
    position: relative;
    padding: 30px 0;
}

.fa-add-size,
.fa-delete-size{
    font-size: 9px;
}

.list-routes{
    list-style: outside none none;
}

.list-routes li{
    font-size: 14px;
    padding-bottom: 5px;
}

.input-group{
    margin-bottom: 15px;
}